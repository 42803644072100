<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.SERVICE.INVOICES") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handleExport"
          >
            Експорт по редове
          </button>
          <router-link
            v-permission="['service-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder ml-2"
          >
            <i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}
          </router-link>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          placeholder="Търсене по фактура, име, код на артикул"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-to-input"
                  type="text"
                  name="date_from"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                  :validateState="{}"
                  v-model="filters.dateFrom"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="6">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-from-input"
                  type="text"
                  name="date_to"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                  :validateState="{}"
                  v-model="filters.dateTo"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="vendors-group" label-for="vendors-input">
                <FormSelect
                  id="vendors-input"
                  :placeholder="$t('FORM_LABELS.VENDOR')"
                  type="select"
                  name="vendors"
                  :options="vendors"
                  clearable="true"
                  v-model="filters.serviceVendorId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="machines-group" label-for="machines-input">
                <FormSelect
                  id="machines-input"
                  :placeholder="$t('FORM_LABELS.MACHINE')"
                  type="select"
                  name="machines"
                  :options="machines"
                  clearable="true"
                  v-model="filters.machineId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="warehouses-group" label-for="warehouses-input">
                <FormSelect
                  id="warehouses-input"
                  :placeholder="$t('FORM_LABELS.WAREHOUSE')"
                  type="select"
                  name="warehouses"
                  :options="warehouses"
                  clearable="true"
                  v-model="filters.serviceWarehouseItemId"
                ></FormSelect>
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingServiceInvoice"
          :expanded.sync="expanded"
          show-expand
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.invoiceDate`]="{ item }">
            {{ item.invoiceDate | formatDate }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['service-update'])"
              :to="{ name: 'edit-service-invoices', params: { id: item.id } }"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.EDIT") }}</span>
              </v-tooltip>
            </router-link>
            <template v-else>Няма права</template>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <table class="my-2">
                <table class="rows-table">
                  <thead>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.NAME") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.CODE") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.MACHINE") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.WAREHOUSE") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.QTY") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.PRICE") }}
                    </th>
                    <th style="padding: 0 10px;">
                      {{ $t("FORM_LABELS.TOTAL_PRICE") }}
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="r in item.rows" :key="r.id">
                      <td style="padding: 0 10px;">{{ r.name }}</td>
                      <td style="padding: 0 10px;">{{ r.code }}</td>
                      <td style="padding: 0 10px;">{{ r.machine }}</td>
                      <td style="padding: 0 10px;">{{ r.warehouse }}</td>
                      <td style="padding: 0 10px;">{{ r.totalQty }}</td>
                      <td style="padding: 0 10px;">{{ r.price }}</td>
                      <td style="padding: 0 10px;">{{ r.totalPrice }}</td>
                    </tr>
                  </tbody>
                </table>
              </table>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";

import {
  FETCH_SERVICE_INVOICES,
  GENERATE_EXPORT_SERVICE_INVOICE_ROWS_URL
} from "@/modules/service-invoices/store/service-invoices.module";

import { FETCH_MACHINES } from "@/modules/machines/store/machine.module";
import { FETCH_SERVICE_VENDORS } from "@/modules/service-vendors/store/service-vendors.module";
import { FETCH_SERVICE_WAREHOUSE_ITEMS } from "@/modules/service-warehouse-items/store/service-warehouse-items.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  components: {
    SearchTools,
    FormSelect,
    FormDatepicker
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      expanded: [],
      totalItems: 100,
      searchQuery: "",
      vendors: [],
      warehouses: [],
      machines: [],
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30,
        totalItems: 100
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        serviceVendorId: null,
        serviceWarehouseItemId: null,
        machineId: null
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        { text: this.$i18n.t("FORM_LABELS.DATE"), value: "invoiceDate" },
        {
          text: this.$i18n.t("FORM_LABELS.VENDOR"),
          value: "serviceVendor"
        },
        {
          text: this.$i18n.t("FORM_LABELS.INVOICE_NUMBER"),
          value: "invoiceNumber"
        },
        {
          text: this.$i18n.t("FORM_LABELS.VALUE_WITH_VAT"),
          value: "totalWithVat"
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.SERVICE.INVOICES"),
        route: { name: "list-service-invoices" }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);

    this.fetchServiceVendors();
    this.fetchServiceWarehouses();
    this.fetchMachines();
  },
  computed: {
    ...mapGetters(["isLoadingServiceInvoice"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      return this.headers;
    }
  },
  methods: {
    fetchServiceVendors() {
      this.$store.dispatch(FETCH_SERVICE_VENDORS).then(data => {
        this.vendors = data.data.items;
      });
    },
    fetchServiceWarehouses() {
      this.$store.dispatch(FETCH_SERVICE_WAREHOUSE_ITEMS).then(data => {
        this.warehouses = data.data.items;
      });
    },
    fetchMachines() {
      this.$store.dispatch(FETCH_MACHINES).then(data => {
        this.machines = data.data.items;
      });
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    doClear() {
      this.searchQuery = "";
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.$url.transformParams(params);
      vm.$store
        .dispatch(FETCH_SERVICE_INVOICES, apiParams)
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-service-invoices" });
          }
        });
    },
    handleExport() {
      this.$store.dispatch(
        GENERATE_EXPORT_SERVICE_INVOICE_ROWS_URL,
        this.$url.transformParams({
          ...this.filters,
          query: this.searchQuery
        })
      );
    }
  }
};
</script>
